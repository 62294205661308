@import '../../sass/abstract';

.head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: size(25);
}

.picture {
    width: size(40);
    height: size(40);
    border-radius: size(10);
}

.meta {
    margin-left: size(20);
    .title {
        margin-bottom: 0;
    }
}
