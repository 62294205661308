@import '../../sass/abstract';

.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: size(-25);
    position: relative;
    img {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
}
.picture {
    overflow: hidden;
    width: size(90);
    height: size(90);
    border-radius: 50%;
    border: size(6) solid $color-white;
}

.nickname {
    display: none;
}

.text {
    font-size: size(20);
    font-weight: 600;
    margin-left: size(10);
}
