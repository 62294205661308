@mixin blanklines {
    &::before {
        content: ' ';
        display: block;
    }
}

@mixin transition($property: all) {
    transition: 0.2s $property;
}
