@import '../../sass/abstract';

.main {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    position: fixed;
    height: size(120);
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;

    &::before {
        content: '';
        box-shadow: 0 0 size(30) rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        width: 80%;
        height: 140%;
        border-radius: 100%;
    }
}

.list {
    background-color: $color-white;
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: stretch;
}

.item {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    list-style: none;
    position: relative;
    margin: size(15) 0;
    z-index: 2;
    & + & {
        border-left: 1px solid $color-grey-light;
    }

    svg {
        z-index: 3;
        position: relative;
        font-size: size(35);
    }
}
.itemCenter {
    z-index: 3;
    border: 0 !important;
    svg {
        color: $color-white;
        font-size: size(60) !important;
    }
}
.itemCenter + .item {
    border: 0 !important;
}
.itemCenter::before {
    content: '';
    position: absolute;
    display: block;
    background: $color-main;
    z-index: 3;
    width: size(140);
    height: size(140);
    border-radius: 100%;
}

.text {
    z-index: 3;
    position: relative;
}

.link {
    display: flex;
    width: 100%;
    height: 100%;
    color: $color-grey-dark;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all;
    border: 0;
    padding: 0;
    .button__children {
        margin-top: size(5);
        font-size: size(14);
        font-weight: 600;
    }
}
.link.active {
    color: $color-main !important;
    .button__children {
        color: $color-grey-dark;
    }
}
