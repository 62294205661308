@import '../../sass/abstract';

.search {
    display: flex;
    align-items: stretch;

    &__field {
        transition: 0.2s padding;
        display: flex;
        background-color: $color-main-light;
        width: size(150);
        margin-right: size(5);
        border-width: 0;
        border-radius: size(3);
        text-align: center;
        padding: size(10);
        font-size: size(25);
        font-family: 'Open Sans', sans-serif;

        &::placeholder {
            color: $color-main-lighter;
        }

        &:hover {
            border-color: #999999;
        }

        &:focus {
            padding: size(20);
            background-color: #ffffff;

            &::placeholder {
                transition: 0.2s all;
                color: #fff;
            }

            + .search__submit {
                padding: 0 5vw;
                width: auto;
                background-color: $color-white;
                color: $color-main;
            }
        }
    }

    &__submit {
        overflow: hidden;
        display: flex;
        width: 0;
        transition: 0.2s all;
        background-color: #e3e0fa;
        border: 0;
        font-weight: 600;
        font-size: size(25);
        color: #ffffff;
        border-radius: size(3);
        font-family: 'Open Sans', sans-serif;
    }
}

.header .search {
    margin: 0;

    &__field {
        width: 50vw;
    }
}
