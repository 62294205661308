@import '../../sass/abstract';

.footer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    line-height: 1;
    padding: size(15) size(20) size(120);
    color: $color-white;
    font-size: size(17);
    text-align: center;
}
.legacy {
    margin: size(10) 0 size(30) 0;
    color: $color-white;
}

.nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.navLink {
    text-align: center;
    display: flex;
    font-family: 'Open Sans', sans-serif;
    padding: size(3) 0;
    color: $color-white;
}

.add {
    position: absolute;
    top: 0;
    left: 0;
}
