@import '../../sass/abstract';

.main {
    background-color: transparent;
    border: 0;
    white-space: nowrap;
    border-radius: 3px;
    padding: 0 2vw;
    font-size: size(20);
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    & + .main {
        margin-left: size(10);
    }
}

.bold {
    font-weight: 600;
}

.colorNone {
    background-color: transparent;
    border: 1px solid #333333;
    color: #333333;
}
