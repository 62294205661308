@import '../../sass/abstract';

.vote {
    position: relative;
    border-bottom-right-radius: size(5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all;
    path {
        fill: $color-grey;
    }
}
.active {
    position: relative;
    z-index: 2;
    path {
        fill: $color-main;
    }
}
.color {
    transition: 0.2s all;
    margin-right: size(7);
    font-size: size(30);
}
