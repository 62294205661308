@import '../../sass/abstract';

.textarea {
    width: 90%;
    border-radius: $radius;
    margin: 0 auto;
    min-height: size(300);
    display: flex;
    padding: size(50);
    font-family: $font-open;
    font-size: size(40);
    font-weight: 600;
    border: 0;
    text-align: center;
    box-shadow: inset 0 0 size(30) rgba(0, 0, 0, 0.1);
    animation: shadowFadeOut 0.2s;
    z-index: -1;
    text-shadow: #fff 0px 1px 1px;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
    -webkit-overflow-scrolling: touch;
    &--focused {
        @include transition;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        min-height: size(400);
        box-shadow: 0 0 size(60) rgba(0, 0, 0, 0.1);
        animation: shadowFadeIn 0.2s;
    }
}
