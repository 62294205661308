@import '../../sass/abstract';

.main {
    position: relative;
    padding: size(10);
    margin-left: auto;
    margin-right: auto;
}

.top {
    text-transform: uppercase;
    font-size: size(20);
    color: $color-black;
    font-weight: bold;
    letter-spacing: size(3);
    text-align: center;
    z-index: 0;
}

.picture {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    border-radius: size(10);
    background-size: 100%;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        opacity: 0;
        path {
            fill: $color-white;
        }
    }
    img {
        max-width: 100vw;
        max-height: 100vw;
        width: 100%;
        object-fit: cover;
        height: 100%;
        object-position: 50% 50%;
    }
}
.pictureRank {
    content: '+1';
    position: absolute;
    bottom: size(50);
    right: size(30);
    color: $color-white;
    font-weight: bold;
    text-shadow: 2px 2px 5px $color-black;
    font-size: size(60);
}
.pictureRankHidden {
    display: none;
}
.meta {
    position: relative;
    display: flex;
    height: size(55);
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom-left-radius: size(10);
    border-bottom-right-radius: size(10);
    @media #{$small} {
        border: 0;
    }
}
.metaNoAuthor {
    justify-content: space-between;
}
