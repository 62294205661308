@function size($size1: '', $size2: '', $size3: '', $size4: '') {
    @if ($size4 != '') {
        @return ($size1 / 640 * 100) vw ($size2 / 640 * 100) vw ($size3 / 640 * 100) vw ($size4 / 640 * 100) vw;
    } @else if ($size3 != '') {
        @return #{$size1 / 640 * 100}vw #{$size2 / 640 * 100}vw #{$size3 / 640 * 100}vw;
    } @else if ($size2 != '') {
        @return #{$size1 / 640 * 100}vw #{$size2 / 640 * 100}vw;
    } @else if ($size1 != '') {
        @return #{$size1 / 640 * 100}vw;
    } @else {
        @return '';
    }
}
