@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800&display=swap);
@-webkit-keyframes Author_shadowFadeIn__1eo_8 {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Author_shadowFadeIn__1eo_8 {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Author_shadowFadeOut__3TUw9 {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Author_shadowFadeOut__3TUw9 {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Author_main__131ja {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: -3.90625vw;
  position: relative; }
  .Author_main__131ja img {
    overflow: hidden;
    width: 100%;
    height: 100%; }

.Author_picture__22_Wb {
  overflow: hidden;
  width: 14.0625vw;
  height: 14.0625vw;
  border-radius: 50%;
  border: 0.9375vw solid white; }

.Author_nickname__3cOqL {
  display: none; }

.Author_text__UHyyE {
  font-size: 3.125vw;
  font-weight: 600;
  margin-left: 1.5625vw; }

@-webkit-keyframes Vote_shadowFadeIn__3FNCL {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Vote_shadowFadeIn__3FNCL {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Vote_shadowFadeOut__2c14c {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Vote_shadowFadeOut__2c14c {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Vote_vote__1ERdN {
  position: relative;
  border-bottom-right-radius: 0.78125vw;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.2s all;
  transition: 0.2s all; }
  .Vote_vote__1ERdN path {
    fill: #999999; }

.Vote_active__Xq_sy {
  position: relative;
  z-index: 2; }
  .Vote_active__Xq_sy path {
    fill: #ff4757; }

.Vote_color__qYKpK {
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  margin-right: 1.09375vw;
  font-size: 4.6875vw; }

@-webkit-keyframes Card_shadowFadeIn__39Ruq {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Card_shadowFadeIn__39Ruq {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Card_shadowFadeOut__Jzp4N {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Card_shadowFadeOut__Jzp4N {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Card_main__d9G09 {
  position: relative;
  padding: 1.5625vw;
  margin-left: auto;
  margin-right: auto; }

.Card_top__3Rc1F {
  text-transform: uppercase;
  font-size: 3.125vw;
  color: black;
  font-weight: bold;
  letter-spacing: 0.46875vw;
  text-align: center;
  z-index: 0; }

.Card_picture__1xzJV {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  border-radius: 1.5625vw;
  background-size: 100%; }
  .Card_picture__1xzJV svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    opacity: 0; }
    .Card_picture__1xzJV svg path {
      fill: white; }
  .Card_picture__1xzJV img {
    max-width: 100vw;
    max-height: 100vw;
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: 50% 50%; }

.Card_pictureRank__5SZSC {
  content: '+1';
  position: absolute;
  bottom: 7.8125vw;
  right: 4.6875vw;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 5px black;
  font-size: 9.375vw; }

.Card_pictureRankHidden__2fjlP {
  display: none; }

.Card_meta__jfz99 {
  position: relative;
  display: flex;
  height: 8.59375vw;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw; }
  @media (min-width: 90vw) {
    .Card_meta__jfz99 {
      border: 0; } }

.Card_metaNoAuthor__2qPhw {
  justify-content: space-between; }

@-webkit-keyframes Title_shadowFadeIn__3D5DX {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Title_shadowFadeIn__3D5DX {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Title_shadowFadeOut__3zsuV {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Title_shadowFadeOut__3zsuV {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Title_title__1eAYb {
  color: #333333; }
  @media (max-width: 600px) {
    .Title_title__1eAYb {
      padding: 0 1.5625vw; } }

@-webkit-keyframes User_shadowFadeIn__2okd6 {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes User_shadowFadeIn__2okd6 {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes User_shadowFadeOut__1m1T5 {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes User_shadowFadeOut__1m1T5 {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.User_head__2gDa0 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.90625vw; }

.User_picture__1UyUH {
  width: 6.25vw;
  height: 6.25vw;
  border-radius: 1.5625vw; }

.User_meta__xVp4p {
  margin-left: 3.125vw; }
  .User_meta__xVp4p .User_title__xNBr3 {
    margin-bottom: 0; }

@-webkit-keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.author__text {
  display: none; }

@-webkit-keyframes Button_shadowFadeIn__2f4L2 {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Button_shadowFadeIn__2f4L2 {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Button_shadowFadeOut__3c1cc {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Button_shadowFadeOut__3c1cc {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Button_main__ren9G {
  background-color: transparent;
  border: 0;
  white-space: nowrap;
  border-radius: 3px;
  padding: 0 2vw;
  font-size: 3.125vw;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center; }
  .Button_main__ren9G + .Button_main__ren9G {
    margin-left: 1.5625vw; }

.Button_bold__31ncU {
  font-weight: 600; }

.Button_colorNone__36BUi {
  background-color: transparent;
  border: 1px solid #333333;
  color: #333333; }

@-webkit-keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.textarea {
  width: 90%;
  border-radius: 1.5625vw;
  margin: 0 auto;
  min-height: 46.875vw;
  display: flex;
  padding: 7.8125vw;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 6.25vw;
  font-weight: 600;
  border: 0;
  text-align: center;
  box-shadow: inset 0 0 4.6875vw rgba(0, 0, 0, 0.1);
  -webkit-animation: shadowFadeOut 0.2s;
          animation: shadowFadeOut 0.2s;
  z-index: -1;
  text-shadow: #fff 0px 1px 1px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  -webkit-overflow-scrolling: touch; }
  .textarea--focused {
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    min-height: 62.5vw;
    box-shadow: 0 0 9.375vw rgba(0, 0, 0, 0.1);
    -webkit-animation: shadowFadeIn 0.2s;
            animation: shadowFadeIn 0.2s; }

@-webkit-keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }
@keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@-ms-viewport {
  width: device-width;
  zoom: 1;
  min-zoom: 1;
  max-zoom: 1;
  user-zoom: fixed; }

@viewport {
  width: device-width;
  zoom: 1;
  min-zoom: 1;
  max-zoom: 1;
  user-zoom: fixed; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  font-family: "Open Sans", Arial, sans-serif;
  color: #595959;
  background-color: #ff4757;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); }

a {
  text-decoration: none;
  color: #333333; }

.container {
  max-width: 770px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  overflow: hidden; }

@media (max-width: 670px) {
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }

h2 {
  margin-bottom: 3.125vw;
  font-weight: 400;
  text-align: center; }

hr {
  height: 0;
  border: none; }

input,
textarea,
select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none; }

#root {
  position: relative; }

ul {
  margin-left: 30px;
  margin-bottom: 20px; }

li {
  list-style-type: square; }

img {
  pointer-events: none; }

.hide {
  display: none; }

.content {
  padding-bottom: 3.90625vw;
  background-color: white;
  border-radius: 1.25vw;
  overflow: hidden;
  position: relative;
  z-index: 1;
  color: #666; }

@-webkit-keyframes Logo_shadowFadeIn__fcDza {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Logo_shadowFadeIn__fcDza {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Logo_shadowFadeOut__3C_nq {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Logo_shadowFadeOut__3C_nq {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Logo_logo__2Ajjw {
  display: flex; }

.Logo_img__2b0Wa {
  height: 9.375vw;
  border-radius: 2.1875vw;
  margin-right: 0.78125vw; }

.Logo_text__3QdPs {
  display: flex;
  display: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 6.25vw;
  line-height: 1;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
  align-items: center;
  color: #000000;
  justify-content: center; }
  @media (max-width: 600px) {
    .Logo_text__3QdPs {
      display: none; } }

@-webkit-keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.search {
  display: flex;
  align-items: stretch; }
  .search__field {
    -webkit-transition: 0.2s padding;
    transition: 0.2s padding;
    display: flex;
    background-color: #ff616e;
    width: 23.4375vw;
    margin-right: 0.78125vw;
    border-width: 0;
    border-radius: 0.46875vw;
    text-align: center;
    padding: 1.5625vw;
    font-size: 3.90625vw;
    font-family: 'Open Sans', sans-serif; }
    .search__field::-webkit-input-placeholder {
      color: #ff949d; }
    .search__field::-moz-placeholder {
      color: #ff949d; }
    .search__field:-ms-input-placeholder {
      color: #ff949d; }
    .search__field::-ms-input-placeholder {
      color: #ff949d; }
    .search__field::placeholder {
      color: #ff949d; }
    .search__field:hover {
      border-color: #999999; }
    .search__field:focus {
      padding: 3.125vw;
      background-color: #ffffff; }
      .search__field:focus::-webkit-input-placeholder {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        color: #fff; }
      .search__field:focus::-moz-placeholder {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        color: #fff; }
      .search__field:focus:-ms-input-placeholder {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        color: #fff; }
      .search__field:focus::-ms-input-placeholder {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        color: #fff; }
      .search__field:focus::placeholder {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        color: #fff; }
      .search__field:focus + .search__submit {
        padding: 0 5vw;
        width: auto;
        background-color: white;
        color: #ff4757; }
  .search__submit {
    overflow: hidden;
    display: flex;
    width: 0;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    background-color: #e3e0fa;
    border: 0;
    font-weight: 600;
    font-size: 3.90625vw;
    color: #ffffff;
    border-radius: 0.46875vw;
    font-family: 'Open Sans', sans-serif; }

.header .search {
  margin: 0; }
  .header .search__field {
    width: 50vw; }

@-webkit-keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes shadowFadeIn {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

#root {
  padding-top: 12.5vw; }

.header {
  padding: 1.5625vw 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ff4757;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  box-shadow: 0 3.125vw 4.6875vw rgba(0, 0, 0, 0.05);
  color: #fff;
  z-index: 5; }
  .header__brand {
    display: flex;
    justify-content: center;
    align-items: center; }
  .header__account {
    border: 0;
    align-self: center;
    font-size: 4.6875vw; }
    .header__account--text {
      display: none; }
    .header__account svg {
      color: #ffffff; }

@-webkit-keyframes Footer_shadowFadeIn__L_Z6C {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Footer_shadowFadeIn__L_Z6C {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Footer_shadowFadeOut__1bLkQ {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Footer_shadowFadeOut__1bLkQ {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Footer_footer__3ZkoG {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  line-height: 1;
  padding: 2.34375vw 3.125vw 18.75vw;
  color: white;
  font-size: 2.65625vw;
  text-align: center; }

.Footer_legacy__1sN8A {
  margin: 1.5625vw 0 4.6875vw 0;
  color: white; }

.Footer_nav__1T-6y {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; }

.Footer_navLink__3cyLF {
  text-align: center;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  padding: 0.46875vw 0;
  color: white; }

.Footer_add__1XslZ {
  position: absolute;
  top: 0;
  left: 0; }

@-webkit-keyframes Navbar_shadowFadeIn__37_FC {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@keyframes Navbar_shadowFadeIn__37_FC {
  0% {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0 0 40% rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes Navbar_shadowFadeOut__1Sk6U {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

@keyframes Navbar_shadowFadeOut__1Sk6U {
  0% {
    box-shadow: 0 0 10px #aaa; }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0 0 10px #aaa; } }

.Navbar_main__3RBJz {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 18.75vw;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  justify-content: center; }
  .Navbar_main__3RBJz::before {
    content: '';
    box-shadow: 0 0 4.6875vw rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    width: 80%;
    height: 140%;
    border-radius: 100%; }

.Navbar_list__PqQ3o {
  background-color: white;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: stretch; }

.Navbar_item__254Wl {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  list-style: none;
  position: relative;
  margin: 2.34375vw 0;
  z-index: 2; }
  .Navbar_item__254Wl + .Navbar_item__254Wl {
    border-left: 1px solid #d9d9d9; }
  .Navbar_item__254Wl svg {
    z-index: 3;
    position: relative;
    font-size: 5.46875vw; }

.Navbar_itemCenter__99eyY {
  z-index: 3;
  border: 0 !important; }
  .Navbar_itemCenter__99eyY svg {
    color: white;
    font-size: 9.375vw !important; }

.Navbar_itemCenter__99eyY + .Navbar_item__254Wl {
  border: 0 !important; }

.Navbar_itemCenter__99eyY::before {
  content: '';
  position: absolute;
  display: block;
  background: #ff4757;
  z-index: 3;
  width: 21.875vw;
  height: 21.875vw;
  border-radius: 100%; }

.Navbar_text__3as_2 {
  z-index: 3;
  position: relative; }

.Navbar_link__sXtG3 {
  display: flex;
  width: 100%;
  height: 100%;
  color: #595959;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  border: 0;
  padding: 0; }
  .Navbar_link__sXtG3 .Navbar_button__children__1hVfR {
    margin-top: 0.78125vw;
    font-size: 2.1875vw;
    font-weight: 600; }

.Navbar_link__sXtG3.Navbar_active__8AOSP {
  color: #ff4757 !important; }
  .Navbar_link__sXtG3.Navbar_active__8AOSP .Navbar_button__children__1hVfR {
    color: #595959; }

