@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800&display=swap');

@import './sass/abstract';

@viewport {
    width: device-width;
    zoom: 1;
    min-zoom: 1;
    max-zoom: 1;
    user-zoom: fixed;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: $font-open;
    color: $color-grey-dark;
    background-color: $color-main;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

a {
    text-decoration: none;
    color: #333333;
}

.container {
    max-width: 770px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
}

@media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

h2 {
    margin-bottom: size(20);
    font-weight: 400;
    text-align: center;
}

hr {
    height: 0;
    border: none;
}

input,
textarea,
select {
    appearance: none;
    outline: none;
}

#root {
    position: relative;
}

ul {
    margin-left: 30px;
    margin-bottom: 20px;
}

li {
    list-style-type: square;
}

img {
    pointer-events: none;
}

.hide {
    display: none;
}

.content {
    padding-bottom: size(25);
    background-color: $color-white;
    border-radius: size(8);
    overflow: hidden;
    position: relative;
    z-index: 1;
    color: #666;
}
