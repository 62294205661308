@import '../../sass/abstract';

.logo {
    display: flex;
}

.img {
    height: size(60);
    border-radius: size(14);
    margin-right: size(5);
}

.text {
    display: flex;
    display: none;
    font-family: 'Open Sans', sans-serif;
    font-size: size(40);
    line-height: 1;
    color: #ffffff;
    font-weight: 300;
    text-align: center;
    align-items: center;
    color: #000000;
    justify-content: center;
    @media (max-width: 600px) {
        display: none;
    }
}
