$font-open: "Open Sans", Arial, sans-serif;

$shine: 5%;

// $color-main: rgb(77, 77, 77);
$color-main: rgb(255, 71, 87);
$color-main-light: lighten($color-main, $shine);
$color-main-lighter: lighten($color-main, 3 * $shine);

$color-black: rgb(0, 0, 0);
$color-black-light: lighten($color-black, $shine);

$color-white: rgb(255, 255, 255);

$color-grey: rgb(153, 153, 153);
$color-grey-dark: darken($color-grey, 5 * $shine);
$color-grey-light: lighten($color-grey, 5 * $shine);

$radius: size(10);
