@import '../../sass/abstract';

#root {
    padding-top: size(80);
}

.header {
    padding: size(10) 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-main;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    box-shadow: 0 size(20) size(30) rgba(0, 0, 0, 0.05);
    color: #fff;
    z-index: 5;

    &__brand {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__account {
        border: 0;
        align-self: center;
        font-size: size(30);

        &--text {
            display: none;
        }

        svg {
            color: #ffffff;
        }
    }
}
