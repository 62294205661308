@keyframes shadowFadeIn {
    0% {
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0 0 40% rgba(0, 0, 0, 0.1);
    }
}
@keyframes shadowFadeOut {
    0% {
        box-shadow: 0 0 10px #aaa;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: inset 0 0 10px #aaa;
    }
}
